import './App.css';
import Name from "./Name";
import {useState} from "react";

function App() {
    const [value, setValue] = useState('')

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    return (
    <div className="App">
      <header className="App-header">
        <input
        onChange={handleChange}
               type="text"/>
        <Name value={value} />
      </header>
    </div>
  );
}

export default App;
